import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
// utils
import { auth, signInWithGoogle } from '../../utils/firebase';
// JoyUI
import Button from '@mui/joy/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HorizontalRule from '@mui/icons-material/HorizontalRule';
// context
import { UserContext } from '../../context/UserContext';
// components
import IttAppBar from '../../components/app/IttAppBar';
import PageLoadingIndicator from '../../components/widgets/PageLoadingIndicator';

function Home() {
  const user = useContext(UserContext);
  const [auser, loading] = useAuthState(auth);

  if (loading === true) {
    return <PageLoadingIndicator title="Home" />;
  }

  return (
    <>
      <IttAppBar title="Home" />
      <div style={{ paddingTop: '10px' }}>
        <Grid
          container
          rowSpacing={2}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={12} justifyItems="center">
            <Typography>
              I'd Tap That Engage - the platform for Brewers
            </Typography>
          </Grid>
          <HorizontalRule />
          {user.isLoggedIn === false && loading === false && !auser && (
            <>
              <Grid item xs={12} justifyItems="center">
                <Typography>Use your Google Accont to Login</Typography>
                <Button
                  color="primary"
                  variant="solid"
                  onClick={signInWithGoogle}
                >
                  Login with Google
                </Button>
              </Grid>
              <Grid item xs={12} />
            </>
          )}
          {user.isLoggedIn === true &&
            user.isGlobalAdmin !== true &&
            loading === false && (
              <>
                <Grid item xs={12}>
                  <Typography>Hello {user.userName} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    You are logged in, however you do not have permission to
                    access any resources here.
                  </Typography>
                </Grid>
              </>
            )}
          {user.isLoggedIn === true && user.isGlobalAdmin === true && (
            <>
              <Grid item xs={12}>
                <Typography>Hello {user.userName} </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link to="/breweries">Manage Breweries</Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/users">Manage Users</Link>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
}

export default Home;
