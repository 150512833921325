import React from 'react';
// Joy UI
import Grid from '@mui/material/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
//
import { XS_CARD_WIDTH, MD_CARD_WIDTH } from '../../utils/constants';

function User({ user }) {
  console.log(user);
  const { displayName, email, customClaims, uid, photoURL } = user;
  let isGlobalAdmin = false;

  if (
    customClaims &&
    customClaims.globalAdmin &&
    customClaims.globalAdmin === true
  ) {
    isGlobalAdmin = true;
  }

  // console.log(`${email} : ${isGlobalAdmin}`);
  return (
    <Card
      row
      variant="outlined"
      sx={{
        minWidth: {
          xs: XS_CARD_WIDTH,
          md: MD_CARD_WIDTH,
        },
        width: {
          xs: XS_CARD_WIDTH,
          md: MD_CARD_WIDTH,
        },
        margin: '4px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography level="h5">{displayName}</Typography>
          <Typography level="body2">{email}</Typography>
          <Typography level="body2">{uid}</Typography>
        </Grid>
        <Grid item xs={4}>
          {isGlobalAdmin && <Typography>Global Admin</Typography>}
        </Grid>
        <Grid item xs={2}>
          {/* <Typography> */}
          {photoURL && <img src={photoURL} alt="user" />}
        </Grid>
      </Grid>
    </Card>
  );
}

export default User;
